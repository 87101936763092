import React from "react"
import styled from "styled-components"

const SwiftcomLogoIcon = ({ fill = "#fff" }) => (
  <SVGStyling
    xmlns="http://www.w3.org/2000/svg"
    width="379.375"
    viewBox="0 0 379.375 79"
  >
    <g transform="translate(-170.353 -375.566)">
      <g transform="translate(170.353 375.566)">
        <path
          className="a"
          d="M242.544,547.719a10.367,10.367,0,0,0,14.661,0l20.525-20.525a10.366,10.366,0,0,0,0-14.66h0l-7.33-7.33A10.37,10.37,0,0,0,257.18,504a1.57,1.57,0,0,0-.24,2.4l9.794,9.794a5.183,5.183,0,0,1,0,7.33Z"
          transform="translate(-225.202 -471.755)"
        />
        <path
          className="b"
          d="M225.842,378.6a10.367,10.367,0,0,0-14.661,0l-20.525,20.525a10.366,10.366,0,0,0,0,14.66h0l7.33,7.33a10.369,10.369,0,0,0,13.219,1.2,1.571,1.571,0,0,0,.24-2.4l-9.794-9.794a5.183,5.183,0,0,1,0-7.33Z"
          transform="translate(-183.472 -375.566)"
        />
        <path
          className="c"
          d="M205.476,593.849l-2.719.742-32.4,8.885a10.365,10.365,0,0,0,8.477,7.509l26.646-17.137"
          transform="translate(-170.353 -541.414)"
        />
        <path
          className="d"
          d="M241.315,582.745l-3.4,2.189,0,0-.828.532L209.137,603.44a10.332,10.332,0,0,0,7.159,4.625l24.185-24.185a5.142,5.142,0,0,0,.834-1.135"
          transform="translate(-199.82 -532.977)"
        />
        <path
          className="e"
          d="M299.357,414.812l-26.645,17.137,2.719-.743,32.4-8.885a10.365,10.365,0,0,0-8.478-7.509"
          transform="translate(-248.123 -405.384)"
        />
        <path
          className="f"
          d="M271.206,391.85l-24.185,24.185a5.138,5.138,0,0,0-.834,1.135l3.4-2.189,0,0,.828-.532,27.944-17.972a10.33,10.33,0,0,0-7.159-4.625"
          transform="translate(-227.97 -387.938)"
        />
      </g>
      <g transform="translate(240.112 390.085)">
        <path
          fill={fill}
          d="M460.856,487.929l5.052-6a.434.434,0,0,1,.612-.051,22.2,22.2,0,0,0,14.565,5.65c4.877,0,7.382-2.241,7.382-5.076,0-3.23-2.637-4.219-9.557-5.8-9.558-2.175-16.347-4.878-16.347-13.644,0-8.371,6.855-14.106,16.742-14.106a26.182,26.182,0,0,1,17.419,6,.439.439,0,0,1,.072.594l-4.571,6.293a.433.433,0,0,1-.618.085,20.819,20.819,0,0,0-12.565-4.73c-4.153,0-6.657,2.175-6.657,4.878,0,3.3,2.7,4.35,9.689,5.932,9.755,2.175,16.215,5.075,16.215,13.446,0,8.635-6.526,14.369-17.6,14.369a29.144,29.144,0,0,1-19.792-7.23A.439.439,0,0,1,460.856,487.929Z"
          transform="translate(-460.753 -445.803)"
        />
        <path
          fill={fill}
          d="M633.517,498.842h8.495a.435.435,0,0,1,.417.308l5.958,19.575a.435.435,0,0,0,.831,0l6.2-19.584a.436.436,0,0,1,.415-.3h7.338a.435.435,0,0,1,.415.3l6.2,19.584a.435.435,0,0,0,.831,0l5.958-19.575a.435.435,0,0,1,.417-.308h8.5a.435.435,0,0,1,.412.576l-11.3,33.207a.436.436,0,0,1-.412.3h-8a.435.435,0,0,1-.417-.312l-5.82-19.7a.435.435,0,0,0-.835,0l-5.882,19.706a.435.435,0,0,1-.417.311h-8a.435.435,0,0,1-.412-.3l-11.3-33.207A.435.435,0,0,1,633.517,498.842Z"
          transform="translate(-591.685 -483.747)"
        />
        <path
          fill={fill}
          d="M873.819,441.146a5.207,5.207,0,1,1,5.273,5.273A5.263,5.263,0,0,1,873.819,441.146Zm.83,9.953H883.4a.435.435,0,0,1,.435.435v33.207a.435.435,0,0,1-.435.435h-8.753a.435.435,0,0,1-.435-.435V451.535A.435.435,0,0,1,874.649,451.1Z"
          transform="translate(-774.593 -436.005)"
        />
        <path
          fill={fill}
          d="M954.285,456.521H960.8a.435.435,0,0,1,.435.435v6.907a.435.435,0,0,1-.435.435h-6.512a.436.436,0,0,0-.435.435v25.429a.435.435,0,0,1-.435.435h-8.357a.435.435,0,0,1-.435-.435V464.734a.435.435,0,0,0-.435-.435h-3.809a.435.435,0,0,1-.435-.435v-6.907a.435.435,0,0,1,.435-.435h3.809a.436.436,0,0,0,.435-.435v-2.531c0-6.13,4.218-10.414,10.217-10.414a12.488,12.488,0,0,1,7.61,2.763.436.436,0,0,1,.12.492l-1.979,5.031a.431.431,0,0,1-.634.209,5.451,5.451,0,0,0-2.876-.783c-1.78,0-3.23,1.582-3.23,3.823v1.411A.436.436,0,0,0,954.285,456.521Z"
          transform="translate(-824.832 -441.426)"
        />
        <path
          fill={fill}
          d="M1066.567,500.855a.436.436,0,0,1-.123.484,12.486,12.486,0,0,1-7.616,2.768c-6,0-10.217-4.284-10.217-10.414V477.451a.435.435,0,0,0-.435-.435h-3.809a.435.435,0,0,1-.435-.435v-6.907a.435.435,0,0,1,.435-.435h3.809a.435.435,0,0,0,.435-.435v-8.489a.435.435,0,0,1,.435-.435h8.357a.435.435,0,0,1,.435.435V468.8a.435.435,0,0,0,.435.435h6.512a.435.435,0,0,1,.435.435v6.907a.435.435,0,0,1-.435.435h-6.512a.435.435,0,0,0-.435.435v14.725c0,2.241,1.45,3.823,3.23,3.823a5.46,5.46,0,0,0,2.866-.776.431.431,0,0,1,.637.218Z"
          transform="translate(-903.842 -454.143)"
        />
        <path
          fill={fill}
          d="M1157.251,472.3c0-12.721,11.073-23.4,24.058-23.4a24.239,24.239,0,0,1,17.285,7.253.438.438,0,0,1-.023.63l-6.392,5.735a.434.434,0,0,1-.612-.034,14.03,14.03,0,0,0-10.257-4.752c-7.58,0-14.04,6.723-14.04,14.567,0,7.91,6.46,14.633,14.04,14.633a14.03,14.03,0,0,0,10.257-4.752.434.434,0,0,1,.612-.034l6.39,5.733a.437.437,0,0,1,.021.632,24.485,24.485,0,0,1-17.28,7.253C1168.324,495.766,1157.251,485.022,1157.251,472.3Z"
          transform="translate(-989.939 -445.803)"
        />
        <path
          fill={fill}
          d="M1346.575,513.346c0-9.689,8.5-17.8,18.654-17.8,10.084,0,18.522,8.107,18.522,17.8s-8.437,17.863-18.522,17.863C1355.078,531.208,1346.575,523.035,1346.575,513.346Zm28.013,0a9.4,9.4,0,1,0-9.36,9.755A9.641,9.641,0,0,0,1374.588,513.346Z"
          transform="translate(-1133.784 -481.245)"
        />
        <path
          fill={fill}
          d="M1580.989,508.731v21.317a.435.435,0,0,1-.435.435H1572.2a.435.435,0,0,1-.435-.435V510.38c0-3.955-2.5-6.723-6.064-6.723-3.823,0-6.591,2.571-6.591,6.2v20.2a.435.435,0,0,1-.435.435h-8.357a.435.435,0,0,1-.435-.435V510.38c0-3.955-2.5-6.723-6-6.723-3.889,0-6.591,2.571-6.591,6.2v20.13a.436.436,0,0,1-.435.435H1528.1a.435.435,0,0,1-.435-.435V496.775a.435.435,0,0,1,.435-.435h8.753a.436.436,0,0,1,.435.435v2.515a.434.434,0,0,0,.789.25,10.177,10.177,0,0,1,8.439-3.991,12.155,12.155,0,0,1,10.423,5.544.43.43,0,0,0,.737-.017c1.944-3.375,5.956-5.527,10.723-5.527C1575.518,495.549,1580.989,501.02,1580.989,508.731Z"
          transform="translate(-1271.373 -481.245)"
        />
      </g>
    </g>
  </SVGStyling>
)

export default SwiftcomLogoIcon

// ===============
//     STYLES
// ===============

const SVGStyling = styled.svg`
  .a,
  .c,
  .d {
    fill: ${({ theme }) => theme.brightRed};
  }
  .b,
  .e,
  .f {
    fill: ${({ theme }) => theme.brightBlue};
  }
  .c,
  .e {
    opacity: 0.2;
  }
  .d,
  .f {
    opacity: 0.5;
  }
`
