import React from "react"

const LinkedInIcon = ({ fill = "#fff" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18.388"
    height="18.388"
    viewBox="0 0 18.388 18.388"
  >
    <path
      fill={fill}
      d="M4.116,18.389H.3V6.112H4.116ZM2.208,4.437A2.218,2.218,0,1,1,4.416,2.209,2.226,2.226,0,0,1,2.208,4.437ZM18.384,18.389h-3.8V12.412c0-1.424-.029-3.251-1.982-3.251-1.982,0-2.286,1.547-2.286,3.148v6.079H6.5V6.112H10.16V7.787h.053A4.006,4.006,0,0,1,13.821,5.8c3.858,0,4.568,2.541,4.568,5.841v6.744Z"
      transform="translate(0 -0.001)"
    />
  </svg>
)

export default LinkedInIcon
