import React from "react"

const PinterestIcon = ({ fill = "#fff" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20.358"
    height="20.358"
    viewBox="0 0 20.358 20.358"
  >
    <path
      fill={fill}
      d="M20.358,10.742A10.18,10.18,0,0,1,7.166,20.465,11.442,11.442,0,0,0,8.431,17.8c.123-.476.632-2.422.632-2.422a2.726,2.726,0,0,0,2.331,1.17c3.07,0,5.283-2.824,5.283-6.333A5.974,5.974,0,0,0,10.4,4.335c-4.392,0-6.727,2.947-6.727,6.161,0,1.494.8,3.353,2.065,3.944.193.09.3.049.341-.135.033-.14.205-.833.283-1.153a.3.3,0,0,0-.07-.291,4.009,4.009,0,0,1-.751-2.323,4.4,4.4,0,0,1,4.6-4.416,4.019,4.019,0,0,1,4.252,4.141c0,2.754-1.391,4.663-3.2,4.663a1.476,1.476,0,0,1-1.506-1.839,20.454,20.454,0,0,0,.841-3.39A1.278,1.278,0,0,0,9.235,8.263c-1.022,0-1.843,1.055-1.843,2.471a3.668,3.668,0,0,0,.3,1.51S6.69,16.5,6.506,17.3a9.853,9.853,0,0,0-.037,2.922,10.18,10.18,0,1,1,13.89-9.481Z"
      transform="translate(0 -0.563)"
    />
  </svg>
)

export default PinterestIcon
