import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import SwiftcomLogoIcon from "../data/images/logos/sc-logo"
import Facebook from "../data/images/icons/facebook-icon"
import Twitter from "../data/images/icons/twitter-icon"
import Instagram from "../data/images/icons/instagram-icon"
import Youtube from "../data/images/icons/youtube-icon"
import Pinterest from "../data/images/icons/pinterest-icon"
import Linkedin from "../data/images/icons/linkedin-icon"
import Input from "../components/reusable-components/input"
import InputButton from "../components/reusable-components/input-button"
import Pdf from "../data/Privacy-Policy-SwiftCom.pdf"

const Footer = () => (
  <FooterSyled>
    <TopFooterSection>
      <LogoSection>
        <Link to="/">
          <SwiftcomLogoIcon />
        </Link>
        <SocialMediaSection>
          <a>
            <Facebook />
          </a>
          <a>
            <Twitter />
          </a>
          <a>
            <Instagram />
          </a>
          <a>
            <Youtube />
          </a>
          <a>
            <Pinterest />
          </a>
          <a>
            <Linkedin />
          </a>
        </SocialMediaSection>
        <MiniContactSection>
          <a href="tel:+27870958580">087 095 8580</a>
          <a href="mailto:sales@swiftcom.co.za">sales@swiftcom.co.za</a>
        </MiniContactSection>
      </LogoSection>
      {/* <LinkSection>
        <Link to="/">Home</Link>
        <Link to="/">Services</Link>
        <Link to="/">About us</Link>
        <Link to="/">Contact us</Link>
        <Link to="/">Blog</Link>
      </LinkSection> */}
      <InputSection>
        <p>Want some awesome news?</p>
        <h4>Subscribe</h4>
        <InputContainer>
          <Input placeholder="Enter email address" />
          <InputButton></InputButton>
        </InputContainer>
      </InputSection>
    </TopFooterSection>
    <CopyrightSection>
      <Link to="/" className="copyright">
        Copyright SwiftCom 2021
      </Link>
      {/* <Link to="/">Terms &amp; Conditions</Link>
      <Link to="/">Disclaimer</Link> 
      <Link to="/">Privacy Policy</Link> */}
      <a href={Pdf} target="_blank">Privacy Policy</a>
      {/* <Link to="/">FAQ</Link>  */}
    </CopyrightSection>
  </FooterSyled>
)

export default Footer

// ====================
//        STYLES
// ====================

const FooterSyled = styled.footer`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.darkBlue};
  height: fit-content;
`
const TopFooterSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 100px 0;
  height: fit-content;
  padding: 0 9%;

  @media (max-width: 1050px) {
    flex-direction: column;
  }
`
const LogoSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 400px;

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    width: 300px;

    svg {
      width: 250px;
    }
  }
`

const SocialMediaSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: fit-content;
  width: 100%;
  align-items: center;
  margin-top: 50px;

  a {
    max-width: fit-content;
    height: fit-content;
  }

  svg {
    width: 20px;
    margin: 0 18px;
  }

  @media (max-width: 1050px) {
    justify-content: center;
  }

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    margin-top: 30px;
    svg {
      margin: 0 10px;
    }
  }
`
const LinkSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1050px) {
    margin: 100px 0;
  }

  a {
    color: white;
    font: normal normal bold 21px/25px Metropolis;
    margin: 15px 0;
  }
`
const InputSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: fit-content;
  width: 400px;
  color: white;

  p {
    font: normal normal 300 16px/19px Metropolis;
    margin-bottom: 5px;
    width: 100%;
  }

  h4 {
    color: white;
    font: normal normal bold 21px/25px Metropolis;
    width: 100%;
  }

  @media (max-width: 1050px) {
    p {
      text-align: center;
    }

    a {
      text-align: center;
    }
  }

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    width: 300px;
  }
`

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: fit-content;
  width: 100%;
  margin-top: 50px;

  div {
    margin-left: 20px;
  }
`
const CopyrightSection = styled.div`
  margin-top: auto;
  padding: 50px 9%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  color: white;
  font: normal normal 300 16px/19px Metropolis;

  a {
    color: white;
  }

  a:nth-child(2) {
    margin-left: auto;
  }

  a:not(:last-child) {
    margin-right: 40px;
  }

  @media (max-width: 998px) {
    padding: 25px 0;
    justify-content: center;

    a:nth-child(2) {
      margin-left: 50px;
    }

    a {
      margin: 25px 50px;
    }

    .copyright {
      order: 5;
    }
  }
`

const MiniContactSection = styled.div`
  display: flex;
  margin: 50px 0;
  width: 100%;

  a {
    margin: 0 20px;
    color: white;

    &:hover {
      color: ${({ theme }) => theme.brightBlue};
    }
  }
`
