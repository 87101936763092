import React from "react"

const InstagramIcon = ({ fill = "#fff" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18.398"
    height="18.394"
    viewBox="0 0 18.398 18.394"
  >
    <path
      fill={fill}
      d="M9.2,6.719a4.716,4.716,0,1,0,4.716,4.716A4.709,4.709,0,0,0,9.2,6.719Zm0,7.782a3.066,3.066,0,1,1,3.066-3.066A3.072,3.072,0,0,1,9.2,14.5Zm6.009-7.975a1.1,1.1,0,1,1-1.1-1.1A1.1,1.1,0,0,1,15.205,6.526Zm3.124,1.116a5.444,5.444,0,0,0-1.486-3.854A5.479,5.479,0,0,0,12.989,2.3c-1.519-.086-6.071-.086-7.589,0A5.472,5.472,0,0,0,1.545,3.784,5.462,5.462,0,0,0,.059,7.638c-.086,1.519-.086,6.071,0,7.589a5.444,5.444,0,0,0,1.486,3.854A5.486,5.486,0,0,0,5.4,20.567c1.519.086,6.071.086,7.589,0a5.444,5.444,0,0,0,3.854-1.486,5.479,5.479,0,0,0,1.486-3.854c.086-1.519.086-6.066,0-7.585Zm-1.962,9.215a3.1,3.1,0,0,1-1.749,1.749c-1.211.48-4.084.369-5.422.369s-4.215.107-5.422-.369a3.1,3.1,0,0,1-1.749-1.749c-.48-1.211-.369-4.084-.369-5.422s-.107-4.215.369-5.422A3.1,3.1,0,0,1,3.774,4.264c1.211-.48,4.084-.369,5.422-.369s4.215-.107,5.422.369a3.1,3.1,0,0,1,1.749,1.749c.48,1.211.369,4.084.369,5.422S16.847,15.65,16.367,16.857Z"
      transform="translate(0.005 -2.238)"
    />
  </svg>
)

export default InstagramIcon
