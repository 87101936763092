import React from "react"

const FacebookIcon = ({ fill = "#fff" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11.255"
    height="21.015"
    viewBox="0 0 11.255 21.015"
  >
    <path
      fill={fill}
      d="M12.127,11.821l.584-3.8H9.062V5.55a1.9,1.9,0,0,1,2.144-2.055h1.659V.257A20.232,20.232,0,0,0,9.92,0C6.915,0,4.95,1.822,4.95,5.119v2.9H1.609v3.8H4.95v9.194H9.062V11.821Z"
      transform="translate(-1.609)"
    />
  </svg>
)

export default FacebookIcon
