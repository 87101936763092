import React from "react"

const TwitterIcon = ({ fill = "#fff" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21.015"
    height="17.068"
    viewBox="0 0 21.015 17.068"
  >
    <path
      fill={fill}
      d="M18.855,7.634c.013.187.013.373.013.56A12.17,12.17,0,0,1,6.614,20.449,12.171,12.171,0,0,1,0,18.515a8.91,8.91,0,0,0,1.04.053,8.626,8.626,0,0,0,5.347-1.84A4.315,4.315,0,0,1,2.36,13.742a5.431,5.431,0,0,0,.813.067,4.555,4.555,0,0,0,1.133-.147A4.308,4.308,0,0,1,.853,9.435V9.381A4.338,4.338,0,0,0,2.8,9.928a4.313,4.313,0,0,1-1.333-5.76,12.243,12.243,0,0,0,8.881,4.507,4.862,4.862,0,0,1-.107-.987,4.311,4.311,0,0,1,7.454-2.947A8.48,8.48,0,0,0,20.428,3.7a4.3,4.3,0,0,1-1.893,2.374,8.634,8.634,0,0,0,2.48-.667,9.258,9.258,0,0,1-2.16,2.227Z"
      transform="translate(0 -3.381)"
    />
  </svg>
)

export default TwitterIcon
