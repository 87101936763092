import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import SwiftcomLogoIcon from "../data/images/logos/sc-logo"

const Header = () => {
  const [headerBackground, setHeaderBackground] = useState("none")

  const handleScroll = () => {
    setHeaderBackground(
      window.pageYOffset >= window.innerHeight ? "#141626" : "none"
    )
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", () => handleScroll)
    }
  }, [])

  return (
    <HeaderStyled background={headerBackground}>
      <Link to="/">
        <SwiftcomLogoIcon />
      </Link>
    </HeaderStyled>
  )
}

export default Header

// ====================
//        STYLES
// ====================

const HeaderStyled = styled.header`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 50px;
  height: fit-content;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 6;

  background-color: ${({ background }) => background};

  @media (max-width: ${({ theme }) => theme.mobileLarge}) {
    padding: 0 30px;
  }

  svg {
    width: 200px;
    display: flex;
    overflow: hidden;
    margin: 26px 0;
    height: auto;
    height: 42px;

    @media (max-width: ${({ theme }) => theme.mobileLarge}) {
      width: 166px;
      margin: 18px 0;
    }
  }
`
