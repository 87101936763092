import React from "react"

const YoutubeIcon = ({ fill = "#fff" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22.416"
    height="15.761"
    viewBox="0 0 22.416 15.761"
  >
    <path
      fill={fill}
      d="M23,6.966a2.817,2.817,0,0,0-1.982-1.995C19.268,4.5,12.258,4.5,12.258,4.5s-7.01,0-8.758.471A2.817,2.817,0,0,0,1.518,6.966,29.547,29.547,0,0,0,1.05,12.4a29.547,29.547,0,0,0,.468,5.43A2.775,2.775,0,0,0,3.5,19.79c1.748.471,8.758.471,8.758.471s7.01,0,8.758-.471A2.775,2.775,0,0,0,23,17.827a29.547,29.547,0,0,0,.468-5.43A29.547,29.547,0,0,0,23,6.966ZM9.965,15.73V9.064L15.824,12.4,9.965,15.73Z"
      transform="translate(-1.05 -4.5)"
    />
  </svg>
)

export default YoutubeIcon
